import { useContext, useEffect } from 'react';
import { GalleryContext } from '../../context/Gallery.context';


export const useScrollEvent = (actionsState) => {

  const {
    imageData,
    number,
    setNumber,
    targetDivRef
  } = useContext(GalleryContext);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = targetDivRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 60) {
        setNumber(prev => prev + 10);
      }
    };
  
    const divRef = targetDivRef.current;
  
    if (divRef) {
      divRef.addEventListener('scroll', handleScroll);
    }
  
    return () => {
      if (divRef) {
        divRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [actionsState, imageData, number]);
};
