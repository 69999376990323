import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const GalleryContext = createContext(null);

export const GalleryProvider = ({ children }) => {
    
  const [imageData, setImageData] = useState(null);
  const [number, setNumber] = useState(50);
  const targetDivRef = useRef(null);


  return (
    <GalleryContext.Provider
      value={{
        imageData,
        setImageData,
        number,
        setNumber,
        targetDivRef
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

GalleryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
