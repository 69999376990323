import { useContext, useEffect } from 'react';
import { GalleryContext } from '../../context/Gallery.context';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { toast } from 'src/utils/notification';

export const useFetchdata = (navData, actionsState) => {

  const {
    setImageData,
  } = useContext(GalleryContext);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ widgetKey: navData.widgetData.key, ...actionsState }, navData.widgetData);
        setImageData(response.targets);
      } catch (error) {
        toast.error('Error fetching gallery data:', error.message);
      }
    };
    if (navData && actionsState?.data) {
      fetchData();
    }
  }, [navData.widgetData.dataID, actionsState.key, actionsState.timestamp ]);
};
